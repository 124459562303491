import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      jki: file(relativePath: { eq: "kidd-inn-new-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kiddinn: file(relativePath: { eq: "kidd-inn-white-bg-new.png" }) {
        childImageSharp {
          fluid(maxWidth: 218) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      littleSeeds: file(relativePath: { eq: "little-seeds-white-bg-new.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ohana: file(relativePath: { eq: "ohana-home.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const centerData = [
    {
      text: () => {
        return (
          <Link to="/kiddinn/">
            Kidd Inn
            <br />
            49 Kaurilands Rd
            <br />
            Titirangi,
            <br />
            Auckland
            <br />
            PH: 09 818 9398
          </Link>
        )
      },
      imageLink: () => {
        return (
          <Link to="/kiddinn/">
            <Image image={data.kiddinn.childImageSharp.fluid} />
          </Link>
        )
      },
    },
    {
      text: () => {
        return (
          <Link to="littleseeds">
            Lil Seeds
            <br />
            86 Kaurilands Rd
            <br />
            Titirangi,
            <br />
            Auckland
            <br />
            PH: 09 818 5062
          </Link>
        )
      },
      imageLink: () => {
        return (
          <Link to="littleseeds">
            <Image image={data.littleSeeds.childImageSharp.fluid} />
          </Link>
        )
      },
    },
  ]

  return (
    <>
      <SEO title="Home Kidd Inn" />
      <div
        className="container-fluid  clearfix"
        style={{ marginBottom: `4rem` }}
      >
        <div className="row">
          <div className="col-xs-8 col-xs-offset-2 col-sm-4 col-sm-offset-4">
            <Image image={data.jki.childImageSharp.fluid} />
          </div>
        </div>
      </div>

      <div
        className="container-fluid horizontal-section-container clearfix"
        id="home-container"
      >
        <div className="row">
          <div className="col-xs-12">
            <div id="home-latest-news" className="section-container clearfix">
              <span className="section-header">
                <h1 className="text">PLEASE SELECT YOUR CENTRE</h1>
              </span>

              <div className="section-content">
                <div className="thumbnail-list row equal-height clearfix">
                  {centerData.map((center, key) => {
                    return (
                      <div
                        className="col-xs-offset-3 col-sm-offset-0 col-xs-6 col-sm-6"
                        key={key}
                      >
                        <div>{center.imageLink()}</div>
                        <h6 className="title text-center visible-xs">
                          {center.text()}
                        </h6>
                      </div>
                    )
                  })}
                </div>
                <div className="thumbnail-list row equal-height clearfix">
                  {centerData.map((center, key) => {
                    return (
                      <div className="item col-sm-6 hidden-xs" key={key}>
                        <div className="thumbnail item-content">
                          <h6 className="title text-center">{center.text()}</h6>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
